 
.card {
  position: relative;
  overflow: hidden;
  width: inherit;
  max-width: 60rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--panel-body-color);
  border-radius: inherit;
  box-shadow: 0 2px 15px 2px var(--panel-shadow-color);
  color: var(--panel-text-color-alt);
}

.content {
  flex: 1;
  padding: 1em 1em 0em 1em;
}
.content > h3 {
  margin: 0;
  font-size: small;
  font-variant: all-small-caps;
  font-weight: normal;
}
.content > header {
  color: var(--panel-text-color);
  background-color: var(--panel-body-color);
  font-size: larger;
  padding-bottom: .5em;
  word-break: break-word;
}
.content > p {
  flex: 1 1 100%;
  margin: 0;
  font-size: medium;
}

.card > a, 
.card > footer a {
  color: inherit;
  text-decoration: none;
}

.card > footer {
  padding: 0 1em;
  font-size: small;
  display: flex;
  align-items: baseline;
  align-self: flex-end;
  border-radius: inherit;
  width: 100%;
  background-color: var(--panel-body-color);
}
.card > footer time {
  flex: 1 1 auto;
}
.card > footer time,
.card > footer > a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.card > footer > a:after {
  content: "•";
  padding: 0 .5em;
}
.card > footer > a > i {
  font-size: inherit;
  font-variant: initial;
  padding-right: .2em;
  vertical-align: middle;
}

.active {
  outline: 2px solid var(--button-primary-color);
}

.illustration {
  flex-direction: column;
  display: flex;
  background: hsl(0deg 0% 97%);
  height: fit-content;
}

.illustration img {
  align-self: center;
  max-width: 320px;
  mix-blend-mode: darken;
}

@media (max-width: 767px) {
  .illustration {
    margin: 1em 1em 0;
    border-radius: 1em;
    overflow: clip;
  }
  .illustration img {
    max-width: 100%;
  }
}
