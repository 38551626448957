@import './styles/colors-light.css';
@import './styles/colors-dark.css';
@import './styles/vars.css';
@import './styles/animations.css';
@import './styles/md.css';
@import './styles/drawer.css';

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

.hidden {
  display: none;
}

.masked {
  filter: blur(3px);
}

.masked:hover {
  filter: blur(0);
}

kbd {
    background-color: #fafbfc;
    border: 1px solid #d1d5da;
    border-bottom-color: #c6cbd1;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #c6cbd1;
    color: #444d56;
    display: inline-block;
    font: 11px SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    line-height: 10px;
    padding: 3px 5px;
    margin-left: 1em;
    vertical-align: middle;
}

/*
 * CHECKBOX
 */
input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

input[type=checkbox] {
  display: block;
  outline: none;
  border-radius: 4px;
  font-size: 24px;
  width: 1em;
  height: 1em;
  background: var(--input-bg-color);
  border: 1px solid var(--separator-default-color);
}

input[type="checkbox"]:checked::before {
  font-family: 'Material Icons';
  font-weight: 500;
  content: 'done';
  display: block;
  color: var(--button-primary-color);
}
/*
[data-theme="dark"] img {
  filter: brightness(50%);
}
*/
