.wrapper {
  display: grid;
  overflow: hidden;
}
.wrapper > img {
  grid-area: 1 / 1 / 2 / 2;
}
.lqip {
  opacity: 1;
}
.lqip.loaded {
  animation: fade-out .5s both;
}

.source {
  opacity: 0;
  z-index: 9;
}
.source.loaded {
  animation: fade-in .5s both;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
