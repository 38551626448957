.list, .grid {
  list-style: none;
  padding: 0 1em;
}

.list {
  margin: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.grid article {
  height: 100%;
  max-width: 320px;
}

.grid article img {
  max-width: 100%;
}

.grid article > a {
  border-top-right-radius: inherit;
}

.grid .item {
  margin: 0.5em;
}

.item {
  margin: 1em;
  outline: none;
  border-radius: 10px;
}

.item:last-child {
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .grid, .list {
    padding: 0;
    display: initial;
  }
  .list .item,
  .grid .item {
    margin: 0;
    border-radius: 0;
    border-bottom: 2px solid var(--main-bg-color);
  }
  .grid article {
    max-width: inherit;
  }
  .item header, .item footer {
    border: none;
  }
}
